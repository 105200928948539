import {sendPrintJob} from "./api";

export function generateGcodePayload(sideFields) {
    const fields = sideFields
        .filter(field => field.text && field.fontName && field.fontSize && field.canvasWidth && field.canvasHeight)
        .map(field => ({
            text: field.text,
            fontName: field.fontName,
            fontSize: field.fontSize,
            topOffsetInMillimeters: field.top,
            leftOffsetInMillimeters: field.left,
            widthInMillimeters: field.width,
            heightInMillimeters: field.height,
            alignment: "left",
            multiline: !field.oneLine,
            lineHeight: field.lineHeight || field.fontSize + 2,
        }));

    if (fields.length === 0) {
        return null;
    }

    const config = {
        paperWidthInMillimeters: sideFields.find(field => field.canvasWidth)?.canvasWidth,
        paperHeightInMillimeters: sideFields.find(field => field.canvasHeight)?.canvasHeight,
        format: "svg",
    };

    return { payload: { fields, config } };
}

function filterFields(fields) {
    return Object.fromEntries(
        Object.entries(fields).map(([cardIndex, card]) => [
            cardIndex,
            Object.fromEntries(
                Object.entries(card).map(([side, sideFields]) => [
                    side,
                    sideFields.map(field => ({
                        text: field.text,
                        fontName: field.fontName,
                        fontSize: field.fontSize,
                        top: field.top,
                        left: field.left,
                        width: field.width,
                        height: field.height,
                        alignment: 'left',
                        multiline: !field.oneLine,
                        lineHeight: field.lineHeight || field.fontSize + 2,
                        canvasWidth: field.canvasWidth,
                        canvasHeight: field.canvasHeight,
                    }))
                ])
            )
        ])
    );
}

export async function processPrintJob(inkLoomData) {
    const filteredFields = filterFields(inkLoomData.fields);

    const jobPayload = {
        name: inkLoomData.name,
        number: inkLoomData.number,
        preview: inkLoomData.preview,
        fields: filteredFields,
    };

    // console.log("Filtered InkLoom data:", jobPayload);

    try {
        await sendPrintJob(jobPayload);
    } catch (error) {
        console.error("Error generating G-code for all cards:", error);
        throw error;
    }
}
